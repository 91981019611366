@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.select-dropdown-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .primary, .secondary {
        font-size: $small-header;
        .custom-select__menu, .custom-select__single-value, .custom-select__placeholder {
            font-size: $small-header;
        }
        .custom-select__menu {
            .custom-select__option {
                &--is-selected, &:hover{
                    font-weight: 600;
                }
            }
        }
        .custom-select__control, .custom-select__control:hover, .custom-select__control--is-focused{
            @include applyTheme($themes) {
                border: 1px solid themed('contrast-border');
                outline: themed('contrast-border');
            }
            min-height: 32px;
        }
        .custom-select__control--is-disabled { cursor: not-allowed; pointer-events: fill; }

        &.select__label, .custom-select__placeholder {
            @include applyTheme($themes) {
                color: themed('label-light');
            }
        }
    }
    .text {
        font-size: $article-text;

        .custom-select__menu, .custom-select__single-value, .custom-select__placeholder {
            font-size: $article-text;
        }

        .custom-select__control {
            min-height: 20px;
            border: none;
        }
    }
    .default {
        .custom-select__control {
            min-height: 32px;
        }
        font-size: 15px;
        .custom-select__control:hover, .custom-select__control--is-focused{
            @include applyTheme($themes) {
              border: 1px solid themed('contrast-light');
              outline: themed('contrast-light');
            }
        }
    }
    .select__label {
        font-size: $caption-text;
        margin-bottom: 3px;
    }

    &.label-left {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
.light.select-dropdown.primary, .light.select-dropdown.secondary, .light.select-dropdown.text {
    .option-row svg { background-color: $solid-blue }
}
.dark.select-dropdown.primary, .dark.select-dropdown.secondary, .light.select-dropdown.text {
    .option-row svg { background-color: $navy-blue }
}
