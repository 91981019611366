@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import 'src/components/styles/base.scss';
@import 'src/components/styles/_theme.scss';

.scenario-generation {
  @include applyTheme($themes) {  
    height: 100%;
    overflow-y: auto;
    background-color: themed('background-dark');
    color: themed('text-main');
    .margin-custom { margin: 25px 0 10px 0; }
    .results-card { 
      font-size: $small-header;
    }
    .grid-layout {
        padding:0;
    }
    .grid-layout, .scenario-view, .scenario-view > .grid-columns, .schedule-panel {
        height: 100%;
        overflow-y: auto;
    }
    .scenario-view {
        .single-column { padding: 20px; }
    }
    .schedule-panel {
      background-color: themed('contrast-background');
    }
  
    .branch-scenario-section {
      justify-content: left;
    }
    .select-container {
      min-width: 240px;
    }
    .select-label, .label-light, .select__label {
        color: themed('label-light') !important;
        font-size: $small-header;
    }
    .schedules {
      margin-top: 20px;
      .results-card {
        display: flex;
        align-items: center;
        .results-card-header .title { margin: 0; }
      }
      .schedules-card {
        .content {
          position: relative;
          width:100%;
        }
        .sliding-panel {
          position: initial;
          .card {
            background-color: transparent;
            box-shadow: none;
            border: none;
          }
        }
      }
    }
    .asset-schedule-link {
      color: themed('secondary-button-text-new');
      text-decoration: underline;
      font-style: italic;
      height: auto;
      justify-content: left;
    }
    .asset-row {
      border-bottom: 1px solid;
      padding: 10px 0;
    }
    .section-divider, .asset-row {
      border-color: themed('border-light');
    }
    .number-input__input { width: 100% }
  }
  &.dark {
    .modal-body-wrapper .results-card {
      border: 1px solid $darker-gray;
    }
  }
  .schedule-details {
    .text-bold { font-size: $article-text ;}
  }
}
.flatpickr-dark {
  .flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
    background-color: $darker-gray;
  }
  .flatpickr-calendar.hasTime .flatpickr-time { border: none; }
}