@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.tippy-tooltip {
  font-family: 'Roboto', sans-serif;
  position: relative;
  display: inline-block;
  padding: 0;
  line-height: 2;
  &.light {
    .tippy-backdrop {
      background-color: #404040 !important;
    }
    .tippy-content {
      color: $lighter-gray !important;
    }
  }

  &.dark {
    .tippy-backdrop {
      background-color: #f5f5f5 !important;
    }
    .tippy-content {
      color: #262626 !important;
    }
  }

  .tippy-content {
    border-radius: 2px;
    font-size: $caption-text;
    line-height: 16px;
    max-width: 200px;
    text-align: left;
    padding: 8px;
  }

  
}
.tippy-popper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999 !important;
}
.tip-span {
  padding: 0 !important;
  display: inherit;
}
