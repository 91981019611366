@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.radio-btn-group {
  display: flex;
  width: 100%;

  &--column {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    .option-group {
      margin: 3px 0;
    }
  }

  &--row {
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
  }

  .option-group {
    display: flex;
    flex-grow: 1;
  }

  .option-icon-group {
    display: flex;
    align-items: center;
    user-select: none;
  }

  .option-label-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include applyTheme($themes) {
      color: themed('text-main');
    }

    .option-label-body {
      margin-left: 40px;
    }
  }

  .radio-btn {
    display: flex;
    border: 1px solid $medium-gray;
    border-radius: 18px;
    flex-shrink: 0;
    margin: 0 10px;
    height: 14px;
    width: 14px;
    &.primary {
      border-color: $solid-blue;
    }
    &--selected {
      border-color: $light-teal;
      .radio-btn-fill {
        background-color: $light-teal;
      }
      &.primary {
        .radio-btn-fill {
          background-color: $solid-blue;
        }
      }
    }
  }

  .radio-btn-fill {
    border-radius: 15px;
    flex-grow: 1;
    margin: 2px;
  }

  .radio-btn-input {
    display: hidden;
    height: 0px;
    width: 0px;
    overflow: hidden;
    opacity: 0;

    &:focus + .option-label-container label .radio-btn {
      box-shadow: 0px 0px 1px 10px rgba(41,150,136,0.1);
    }

    &:disabled + .option-label-container {
      @include applyTheme($themes) {
        color: themed('text-disabled');
        .radio-btn {
          border-color: themed('text-disabled');
          &--selected .radio-btn-fill {
            background-color: themed('text-disabled');
          }
        }
      }      
    }
  }

  .radio-btn {
    &.invalid{
      border-color: $error !important;
    }
    &--selected.invalid .radio-btn-fill {
      background-color:  $error !important;
    }
  }
  /* Themes */

  &.dark {
    .radio-btn {
      border-color: $light-teal;
      &--selected {
        border-color: $light-teal;
        .radio-btn-fill {
          background-color: $light-teal;
        }
      }
    }

    .radio-btn-input:focus + label .radio-btn {
      box-shadow: 0px 0px 1px 10px rgba(6,175,186,0.1);
    }
  }

}
