@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.overview-with-nav {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  @include applyTheme($themes) {
    background: themed('background-main-new');
    
  }
  .gis-top-nav {
    @include applyTheme($themes) {
      background: themed('contrast-neutral-new');
    }
  }
  .workspace-overview {
    display: flex;
    flex-direction: column;
    padding: 50px;
    font-size: $small-header;
    .page-header-row {
      width: calc(100%-20px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      margin-left: 20px;
      .refresh-button {
        border-radius: 4px;
        font-weight: bold;
        width: 150px;
        @include applyTheme($themes) {
          box-shadow: 2px 2px 6px 0 themed('button-shadow-new');
          background: themed('contrast-neutral-new');
          color: themed('secondary-button-text-new');
        }
        &:hover {
          border-radius: 4px;
          font-weight: bold;
          @include applyTheme($themes) {
            box-shadow: 2px 2px 6px 0 themed('button-shadow-new');
            background: themed('contrast-neutral-new');
            color: themed('secondary-button-text-new');
          }
        }
      }
    }
    .header-row {
      width: calc(90% - 50px);
      .header {
        font-size: $caption-text;
        @include applyTheme($themes) {
          color: themed('text-header-new');
        }
        #calendar-svg {
          @include applyTheme($themes) {
            fill: themed('text-header-new');
          }
        }
      }
    }
    .arrow-row {
      display: flex;
      width: 100%;
      height: 100%;
      .vertical-lines {
        .vertical-div-top {
          height: 19px;
          width: 1px;
        }
        .vertical-div-bottom {
          height: calc(100% - 19px);
          width: 1px;
        }
        .vertical-div-border {
          border-left: 1px dashed #95A6B8;
        }
      }
      .version-row {
        display: flex;
        align-items: flex-end;
        padding: 2px;
        background: transparent;
        height: 100%;
        width: 100%;
        .center-arrows {
          display: flex;
          align-items: flex-start;
          width: 100%;

          .horizontal-div {
            height: 17px;
            width: 20px;
            vertical-align: top;
          }
          .horizontal-div-color {
            border-bottom: 1px dashed #95A6B8;
          }
          .arrow-right {
            width: 0; 
            height: 0;
            margin-top: 12px; 
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid transparent;
          }
          .arrow-right-color {
            border-left: 5px solid #95A6B8;
          }
       }
        .expandable-section .content-container--active {
          @include applyTheme($themes) {
            background: themed('contrast-neutral-new');
          }
          .scenario-row {
            width: 100%;
            @include applyTheme($themes) {
              color: themed('text-header-new');
            }
            #calendar-svg {
              @include applyTheme($themes) {
                fill: themed('text-header-new');
              }
            }
            .scenario-section {
              width: calc(90% - 40px);
              .analysis-section {
                display: flex;
                flex-wrap: wrap;
                min-width: 200px;
                .info-badge {
                  display: flex;
                  width: fit-content;
                  padding: 5px 15px 5px 15px;
                  text-align: center;
                  border-radius: 12px;
                  margin-left: -3px;
                  margin-right: 10px;
                  margin-bottom: 5px;
                  @include applyTheme($themes) {
                    background: themed('background-main-new');
                    color: themed('text-new');
                  }
                  .multibadge-container {
                    margin-left: 0;
                  }
                  .analysis-name {
                    padding-left: 5px;
                  }
                }
                .analyses-empty {
                  width: 150px;
                  @include applyTheme($themes) {
                    color: themed('text-header-new');
                  }
                }
              }
            }
          }
          .scenario-info {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .multibadge-container {
              margin-left: 0;
              .select-badge-badge {
                @include applyTheme($themes) {
                  color: themed('text-header-new');
                  border-color: themed('text-header-new');
                }
              }
            }
            .scenario-name {
              padding-left: 10px;
              @include applyTheme($themes) {
                color: themed('text-new');
              }
            }
            .scenario-dates {
              margin-right: 10px;
              width: 150px;
              &:first-of-type {
                width: 23px;
                #calendar-svg {
                  @include applyTheme($themes) {
                    fill: themed('text-new');
                  }
                }
              }
              &.calendar-icon {
                padding-top: 5px;
              }
              &-empty {
                width: 150px;
                @include applyTheme($themes) {
                  color: themed('text-header-new');
                }
              }
              &.badge-empty {
                padding-top: 2px;
                #calendar-svg {
                  @include applyTheme($themes) {
                    fill: themed('text-header-new');
                  }
                }
              }
              &.date-badge {
                padding: 5px;
                text-align: center;
                border-radius: 12px;
                margin-left: -3px;
                @include applyTheme($themes) {
                  background: themed('background-main-new');
                  color: themed('text-new');
                }
              }
            }
            &:first-of-type {
              width: 185px;
            }
          }
          .no-scenarios {
            padding-left: 15px;
            font-style: italic;
            @include applyTheme($themes) {
              color: themed('text-header-new');
            }
          }
        }
      }
    }
    .version-expandable {
      &-header {
        border-radius: 5px;
        @include applyTheme($themes) {
          background: themed('contrast-neutral-new');
        }
        &.expandable-section .header {
          align-items: center;
          @include applyTheme($themes) {
            color: themed('text-new');
          }
          .grid-layout {
            padding: 5px 10px;
            .workspace-name {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .dot {
                height: 4px;
                width: 4px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 10px;
                @include applyTheme($themes) {
                  background: themed('text-header-new');
                }
              }
            }
          }
          .toggle-content {
            height: auto;
          }
          &:hover {
            @include applyTheme($themes) {
              color: themed('text-new');
            }
          }
        }
      }
      &-text {
        .notes {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .branch_hierarchy {
      width: calc(100% - 30px);
      margin-left: 28px;
    }
    .loading-skeleton-new-theme {
      .ssc-line, .ssc-circle, .ssc-square, .ssc-head-line {
        @include applyTheme($themes) {
            background-color: themed('skeleton-light-new');
        }
      }
    }
  }
}
