@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.custom-checkbox {
  display: inline-block;
  margin: 0 5px 0 0;
  position: relative;
  height: 17px;
  width: 17px;

   input {
      opacity: 0;
      cursor: pointer;
    }

  .check-container {
    cursor: pointer;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 2px solid #707070;
    border-radius: 2px;
    display: flex;
    height: 17px;
    width: 17px;  
  }

  .check-input {
    margin: 0;
    height: 17px;
    width: 17px;  
  }

  .check {
    content: '';
    position: absolute;
    width: 11px;
    height: 6px;
    top: 2px;
    left: 1px;
    background: transparent;
    border: 2px solid $white;
    border-top: none;
    border-right: none;
    transform: rotate(-50deg);
  }

  .dash {
    content: '';
    position: absolute;
    width: 70%;
    height: 2px;
    top: 6px;
    left: 15%;
    background: transparent;
    border: none;
    border-bottom: 2px solid $white;
  }
  
  &--checked {
    .check-container {
      background-color: $light-teal;
      border-color: transparent;
    }
    &.primary {
      .check-container {
        background-color: $solid-blue;
      }
      .check { border-color: $white !important }
    }
  }

  &--disabled {
    .check-container {
      background-color: rgba($light-gray, 0.5);
      border-color: transparent;
    }

    input {
      cursor: not-allowed !important;
    }

    .dash, .check {
      border-color: #AAAAAA;
    }
  }

  @include applyTheme($themes) {
    .dash, .check {
      border-color: themed('background-dark');
    }

    &--disabled .dash,
    &--disabled .check {
      border-color:  #AAAAAA;
    }
  }
}
