@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.hierarchy-tree-view {
  @include applyTheme($themes) {
    .node-wrapper {
        white-space: pre;
    }
    .tree-node {
        display: flex;
        align-items: center;
        min-height: 35px;
        .tree-icon {
            margin-right: 8px;
            border-radius: 50%;
            background-color: $white;
            cursor: pointer;
            background-repeat: no-repeat;
            width: 12px;
            height: 11px;
            background-size: contain;
            text-indent: -9999px;

            &.open-icon {
                background-image: url(/resources/images/plus-circle.svg);
            }
            &.close-icon {
                background-image: url(/resources/images/minus-circle.svg);
            }
            &.empty-icon {
                background-color: themed('label-light');
                width: 5px;
                height: 5px;
            }
        }
        .node-content-wrapper {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 6px;
            transition: all 0.2s ease;
            .titles {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                .node-title {
                font-size: 13px;
                font-family: 'Roboto', sans-serif;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100px;
                overflow: hidden;
                @include applyTheme($themes) {
                    color: themed('text-new');
                }
                }
            }
            .node-check {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                min-width: 24px;
                height: 16px;
                padding: 4px;
                font-size: 12px;
                border-radius: 16px;
                margin-left: 20px;
                font-weight: 600;
            }
        }
    }
    .tree-wrapper {
        width: 50%;
        padding: 0 0 0 16px;
        &.simple {
        overflow: auto;
        }
    }
    .lib-icon {
        margin-top: 100px;
    }
  }
}
  