@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.analysis-modal {
  align-self: flex-end;

  .modal-message {
    padding: 0 5px;
  }

  .toggle-modal-btn span {
    align-items: center;
    display: flex;

    i {
      margin-left: 5px;
    }
  }

  .modal-body {
    display: flex;
    width: 800px !important;
    z-index: 1001;
    max-height: 96%;
    height: 83% !important;
    .header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      h2 {
        margin: 0;
        @include applyTheme($themes) {
          color: themed('text-header');
        }
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }

    .analysis-name-section, .feeder-selection-section {
      margin-top: 15px;
    }

    // Analysis Selection Section Styles
    .analysis-selection-section {
      margin: 25px 0;
    }

    .analysis-message,
    .existing-results {
      @extend .flex-centered;
      padding: 5px 0;
    }

    .analysis-options {
      display: grid;
      display: -ms-grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 15px;
      margin: 10px 0;
    }

    .analysis-type {
      background-color: transparent;
      border: none;
      cursor: pointer;
      height: 100px;
      padding: 0;
      width: 100%;

      &:disabled {
        cursor: default;
        .card {
          @include applyTheme($themes) {
            color: themed('text-disabled');
          }
        }
      }

      .card,
      .card .content {
        @extend .flex-centered;
        flex-grow: 1;
        height: 100%;
        pointer-events: none;
        margin: 0;
      }


      &--active,
      &:hover:not(:disabled) {
        .card.light {
          border: 1px solid #06AFA8;
          box-shadow: 0 1px 4px 1px rgba(#06AFA8, 0.75);
        }

        .card.dark {
          border: 1px solid #00857B;
          box-shadow: 0 1px 4px 1px rgba(#00857B, 0.75);
        }
      }
    }

    // IE and Edge needs to know the exact column and row of grid
    @mixin positioned-grid-column-row() {
      $elements: 20;
      @for $i from 1 to $elements {
        .analysis-option:nth-child(3n+1) {
          -ms-grid-column: 1;
          -ms-grid-row:$i /3 + 1;
        }
        .analysis-option:nth-child(3n+2) {
          -ms-grid-column: 2;
          -ms-grid-row:$i /3 + 1;
        }
        .analysis-option:nth-child(3n+3) {
          -ms-grid-column: 3;
          -ms-grid-row:$i /3 + 1;
        }

        .analysis-option:nth-child(1),
        .analysis-option:nth-child(2),
        .analysis-option:nth-child(3) {
          -ms-grid-row: 1;
        }
        .analysis-option {
          max-width: 125px;
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    // Edge support to position grid
    @supports (-ms-ime-align:auto) {
      @include positioned-grid-column-row;
    }
    // IE support to position grid
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @include positioned-grid-column-row;
    }

    .analysis-configuration {
      display: flex;
      flex-direction: column;
      position:absolute;
      top: 50px;
      margin: 0 25px 0 0;
      overflow-y: auto;
      max-height: 90%;
      padding-bottom: 25px;
      padding-right: 10px;
      width: 95%;
      height: 100%;
      h2 {
        @include applyTheme($themes) {
          color: themed('text-header');
        }
      }

      .battery-sizing-config {
        .selected-node-row {
          padding-top: 10px;
        }
      }
    }
    .date-range {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      &-select {
        min-width: 195px;
        max-width: 195px;
        margin-bottom: 5px;
      }
      .select-dropdown-section { flex-grow: 0; }
    }
    .message-text {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-bottom: 10px;
      span { margin-left: 6px; }
    }
    .data-interpolation {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      width: 100%;
      &-select {
        margin-top: 5px;
      }
      &:last-of-type {
        margin-left: 16px;
      }
      &-example {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        width: 48%;
        max-width: 100%;
      }
    }
    .sampling-chart {
      .recharts-wrapper {
        left: -45px
      }
      .recharts-legend-item { margin: 0 0px 9px 42px !important; }
      &:last-of-type {
        margin-left: 16px;
      }
    }
    .card.dark {
      box-shadow: 0 4px 8px -2px $black, 0 4px 8px -2px $black;
    }

    .example-label {
      padding: 10px 5px 0 5px;
    }

    // Generic Panel Styles
    .config-panel {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 15px;
      width: 100%;
    }

    .error {
      padding: 8px 0;
    }

    .title-text {
      margin: 5px 0;
    }

    .menu-title {
      font-size: $title-text;
      font-weight: 400;
      margin: 0 0 5px 0;
      padding-bottom: 5px;
      width: 100%;
      @include applyTheme($themes) {
        color: themed('text-contrast');
      }
    }

    .menu-row {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .menu-footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;

      .custom-btn {
        width: 110px;
        &:first-of-type {
          margin-right: 12px;
        }
      }
    }

    .option-label {
      display: flex;
      align-items: center;
      font-size: $body-text;
    }

    .label {
      flex-shrink: 0;
      margin: 0 5px 5px 0;
      text-align: left;
    }

    .file-select {
      display: flex;
      width: 66%;

      &-label {
        font-size: $body-text;
        font-weight: 400;
        align-items: flex-end;
        background-color: transparent;
        border-bottom: 1px dashed $medium-gray;
        cursor: pointer;
        display: flex;
        height: 25px;
        width: 100%;
        @include applyTheme($themes) {
          color: themed('text-contrast');
        }
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 5px;
        }
      }

      &-input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        &:focus + .load-forecast-label {
          border-bottom: 1px dotted $pacific-blue;
        }
      }
    }

    .program-row .label {
      margin: 10px 0;
    }
    .controlmodes-selection {
      .radio-button-group {
        width: 100%; 
        > span:first-of-type{
          grid-column-start: 2;
        }
      }
      .asset-name { min-width: 155px; }
      .asset-group, .controlmode-types {
        display: grid;
        // grid-gap: 5px;
        align-items: center;
        text-align: left;
        padding: 6px 0px 6px 15px;
        width: 100%;
      }
      .radio-btn-group { display: grid; }
      .content .asset-group { display: flex }
      .asset-group:hover {
        @include applyTheme($themes) {
          background-color: themed('background-dark');
        }
      }
    }
  }
}
.upgrade-modal {
  .status-details { margin-top: 15px; }
}