@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.results-comparison.light {
  background: $gray-blue;
  color: $black;
  .calendar-picker-container.light,
  .number-input-all.light .number-input__input {
    border-color: $lighter-blue;
  }
}
.results-comparison {
  height: 100%;
  overflow-y: auto;
  .title-column {
    @include applyTheme($themes) {
      box-shadow: 0 15px 19px -14px themed('border-neutral');
      margin-bottom: 15px;
    }
  }
  
  .results-comparison-card {
    font-size: $small-header;
    .no-violations-placeholder {
      padding-top: 10px;
    }
    .chart-placeholder {
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .calendar-button {
      font-size: $small-header !important;
    }
    .no-margin {
      margin: 0 !important;
      display: flex;
      justify-content: space-between;
      height: 44px;
    }
    .branch-scenario-section {
      margin-right: 0;
    }
    .select-container {
      width: 100%;
    }
    .select-label {
      @include applyTheme($themes) {
        color: themed('label-light') !important;
        font-size: $small-header;
      }
    }
    .space-bottom {
      margin-bottom: 5px;
    }
    .calendar-picker-container {
      min-width: 180px;
      max-width: 100%;
      align-self: end;
      z-index: 99;
      .calendar-button.text-btn {
        max-height: 30px;
      }
    }
    .over-limit-note {
      padding-top: 5px;
    }
    .chart-skeleton {
      width: 100%;
      padding: 0 50px;
      height: 212px;
      display: flex;
      flex-direction: column;
      .skeleton-select-row {
        padding: 25px 0 15px 0;
      }
      .skeleton-chart-row {
        display: flex;
        justify-content: center;
      }
    }
    .recharts-wrapper {
      max-width: 100%;
      margin: auto;
      .comparison {
        text-align: right;
      }
      .positive {
        color: red;
      }
      .negative {
        color: green;
      }
    }
    .item-number {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .warning-value {
        display: flex;
        line-height: 20px;
      }
    }
    .item-text {
      display: flex;
      align-items: center;
      padding-right: 5px;
      .help-icon {
        user-select: none;
        font-size: 16px;
        @include applyTheme($themes) {
          color:  themed('text-dark');
        }
      }
    }
    .comparison-percentage {
      display: flex;
      align-items: center;
      padding-left: 15px;
      #comparison-icon {
        padding-top: 3px;
      }
      .percentage {
        margin-left: 5px;
        font-size: $caption-text;
        &.increased {
          color: #F45614;
        }
        &.decreased {
          color: #2A9A0A;
        }
      }
    }
    .comparison {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: $caption-text;
      #comparison-icon {
        padding-left: 5px;
      }
      .counts {
        width: 25px;
        text-align: left;
      }
    }
    #comparison-icon[data-increased=true] {
      .injected-svg {
        transform: rotate(180deg);
      }
      .outer {
        fill: #F45614;
      }
    }
    &.top-section {
      .results-expandable-section {
        border: none;
      }
    }
    &.summary-card {
      .content {
        padding-right: 26px;
      }
      .results-expandable-section {
        padding-top: 10px;
      }
    }

    .npv-values {
      display: grid;
      grid-template-columns: auto minmax(0, 100px) minmax(0, 100px);
      grid-auto-rows: 30px;
      align-items: center;
      column-gap: 25px;

      .date-picker__input {
        width: 100%;
        min-width: 90px;
        height: 60%;
        font-size: $small-header;
      }

      .color-separator {
        border-top: 3px solid;

        &.selected-project {
          border-color: $graph-g8;
        }
        &.all-project {
          border-color: $graph-g2;
        }
      }

      .completion-date {
        grid-column: 2 / span 2;
      }
    }

    .npv-heading {
      .selected-project {
        .item-text-title:before {
          color: $graph-g8;
        }
      }

      .all-project {
        .item-text-title:before {
          color: $graph-g2;
          margin-right: 8px;
        }
      }

      .item-text-title {
        display: flex;
        align-items: center;
        
        &:before {
          content: '⬤';
          font-size: 8px;
        }
      }
      .custom-select__menu { min-width: 140px; }
    }
  }
  .primary-title {
    display: flex;
    align-items: center;
    .primary-help-icon {
      padding-left: 5px;
      font-size: 14px;
      @include applyTheme($themes) {
        color:  themed('disabled-icon-foreground');
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .single-column .btn-overview-asset {
    display: flex;
    .custom-btn { border-radius: 0; }
    .custom-btn:first-of-type {
      border-top-left-radius: 4px;
    }
    .custom-btn:last-of-type {
      border-top-right-radius: 4px;
    }
    .custom-btn {
        border: 1px solid $solid-blue;
        &.primary-btn {
          color: $white;
          background: $solid-blue;
        }
        &.secondary-btn {
          color: $solid-blue;
        }
    }
  }
  .asset-lists {
    @include applyTheme($themes) {
      .single-asset {
        .results-card { 
          margin-bottom: 0;
          align-items: center;
          display: flex;
          padding: 3px 20px;
          border-radius: 0;
          min-height: 35px;
          .content { width: 100%; }
          .item-number { justify-content: center; }
        }
      }
      .single-asset:nth-child(even) .results-card { background-color: themed('bakground-very-light') }
      .loading-skeleton {
        .ssc {
          background-color: themed('background-dark');
          padding: 10px;
        }
        .ssc-square {
          margin: 10px 0;
        }
        &.dark .ssc { border: 1px solid themed('contrast-border') }
      }
    }
  }
}
