@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.grid-layout {
    display: flex;
    padding: 15px;
    flex-direction: column;
    .grid-layout-header {
        margin-bottom: 5px;;
    }
    .grid-layout-contents{
        width: 100%;
    }
    .grid-columns {
        display: grid;
        grid-gap: 5px 15px;
        &.items-centered {
            align-items: center;
            min-height: 30px;
        }
        &.auto {
            grid-auto-columns: 1fr;
            grid-auto-flow: column;
        }
        &.small-gap {
            grid-gap: 3px;
        }
    }
    .auto-fit {
        grid-template-columns: repeat(auto-fit,minmax(0, 1fr));
    }
    .one-one {
        grid-template-columns: 1fr 1fr;
    }
    .one-one-one-five {
        grid-template-columns: 1fr 1fr 1fr 5fr;
    }
    .one-two {
        grid-template-columns: 1fr 2fr;
    }
    .one-two-five {
        grid-template-columns: 1fr 2fr 5fr;
    }
    .one-seven {
        grid-template-columns: 1fr 7fr;
    }
    .one-six {
        grid-template-columns: 1fr 6fr;
    }
    .one-five-two {
        grid-template-columns: 1fr 5fr 2fr;
    }
    .two-two-four {
        grid-template-columns: 2fr 2fr 7fr;
    }
    .two-one-one {
        grid-template-columns: 2fr 1fr 1fr;
    }
    .two-one-two-two {
        grid-template-columns: 2fr 1fr 2fr 2fr;
    }
    .two-two-two-one{
        grid-template-columns: repeat(3, minmax(0, 2fr)) 1fr;
    }
    .two-two-two-two {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .three-one-three {
        grid-template-columns: 3fr 1fr 3fr;
    }
    .one-three-one {
        grid-template-columns: 1fr 3fr 1fr;
    }
    .three-three-one {
        grid-template-columns: repeat(2, minmax(0, 3fr)) 1fr;
    }
    .four-two {
        grid-template-columns: 4fr 2fr;
    }
    .three-one-one {
        grid-template-columns: 3fr 1fr 1fr;
    }  
    .eight {
        grid-template-columns: 8fr;
    }
}

